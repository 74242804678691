import { FC, useState, useRef, useEffect } from "react";
import cx from "classnames";
import { Document, Page, pdfjs } from "react-pdf";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import { DownloadIcon, RedirectIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useResourceSignedUrlQuery } from "@/fetch/gworld";
import { Skeleton } from "@/components/Loader";
import { useOpenExternalURL, useResponsive, useTrackers } from "@/hooks";
import styles from "./ResourcePdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  resourceId: number;
  onClose: () => void;
  title: string;
  isDownloadable?: boolean;
};

const ResourcePdfViewer: FC<Props> = ({
  resourceId,
  onClose,
  title,
  isDownloadable,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const openExternalLink = useOpenExternalURL();
  const { isMobile } = useResponsive();
  const { track } = useTrackers();
  const pagesContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    data: resourceResult,
    isLoading: isGettingUrl,
    isFetched: isUrlFetched,
  } = useResourceSignedUrlQuery(
    resourceId,
    {},
    { refetchOnWindowFocus: false }
  );
  const { data: downloadResourceResult, isLoading: isGettingDownloadUrl } =
    useResourceSignedUrlQuery(resourceId, { download: true });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const pdfUrl = resourceResult?.url;
  const downloadUrl = resourceResult?.url;

  const pagesArray = new Array(numPages).fill(1);

  const openInNewTab = () => {
    openExternalLink(pdfUrl || "");
  };

  const onDownload = () => {
    openExternalLink(downloadUrl || "");
    track("Resource PDF Downloaded", {
      eventId: "resource-pdf-downloaded",
      resourceId: resourceId,
    });
  };

  const isLoading = isGettingUrl && !isUrlFetched;

  const mobilePageWidth = pagesContainerRef.current?.offsetWidth || 300;
  const mobilePageHeight = mobilePageWidth * 1.41;

  useEffect(() => {
    track("Resource PDF Viewer Opened", {
      eventId: "resource-pdf-viewer-opened",
      resourceId: resourceId,
    });
  }, []);

  const handleChangePage = (newPage: number) => {
    track("Resource PDF Page Changed", {
      eventId: "resource-pdf-page-changed",
      resourceId: resourceId,
      page: newPage,
    });
    setPageNumber(newPage);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      <DialogTitle
        hasCloseButton
        onClose={onClose}
        className={styles.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {isLoading ? (
          <div className={styles.documentContainer}>
            <div className={styles.leftContainer}>
              {[1, 2, 3].map((each) => (
                <Skeleton
                  key={each}
                  variant="rectangular"
                  width={178}
                  height={251}
                  className={styles.loadingSidePage}
                />
              ))}
            </div>
            <div className={styles.mainPageContainer}>
              <Skeleton
                variant="rectangular"
                className={styles.loadingMainPage}
              />
            </div>
          </div>
        ) : (
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className={styles.documentContainer}
          >
            <div className={styles.leftContainer} ref={pagesContainerRef}>
              {pagesArray.map((each, idx) => (
                <div key={idx} className={styles.sidePageContainer}>
                  <Page
                    pageNumber={idx + 1}
                    className={cx(styles.sidePage, {
                      [styles.pageIndicator]: pageNumber === idx + 1,
                    })}
                    width={isMobile ? mobilePageWidth : 178}
                    height={isMobile ? mobilePageHeight : 251}
                    onClick={
                      isMobile ? undefined : () => handleChangePage(idx + 1)
                    }
                  />

                  <Typography variant="body1" color="text.contrast">
                    {idx + 1}
                  </Typography>
                </div>
              ))}
            </div>
            <div className={styles.mainPageContainer}>
              <Page
                pageNumber={pageNumber}
                className={styles.mainPage}
                width={727}
                height={1028}
              />
            </div>
          </Document>
        )}
      </DialogContent>
      {isDownloadable && (
        <DialogActions className={styles.actionContainer}>
          <Button
            startIcon={<DownloadIcon />}
            onClick={onDownload}
            className={styles.downloadButton}
          >
            Download
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ResourcePdfViewer;
