import MuiStepper, { StepperProps } from "@mui/material/Stepper";
import MuiStep, { StepProps } from "@mui/material/Step";
import MuiStepLabel, { StepLabelProps } from "@mui/material/StepLabel";
import MuiStepButton, { StepButtonProps } from "@mui/material/StepButton";

const Stepper: React.FC<StepperProps> = (props) => <MuiStepper {...props} />;
const Step: React.FC<StepProps> = (props) => <MuiStep {...props} />;
const StepLabel: React.FC<StepLabelProps> = (props) => (
  <MuiStepLabel {...props} />
);
const StepButton: React.FC<StepButtonProps> = (props) => (
  <MuiStepButton {...props} />
);

export default Stepper;
export { Step, StepLabel, StepButton };
