import { FC, Fragment } from "react";
import Link from "next/link";
import Typography from "@/components/Typography";
import cx from "classnames";
import { useTrackers } from "@/hooks";
import styles from "./SecondStepper.module.scss";

const Connector: FC<{ direction: "vertical" | "horizontal" }> = ({
  direction,
}) => {
  return (
    <div
      className={cx(styles.connectorContainer, {
        [styles.horizontalConnectorContainer]: direction === "horizontal",
      })}
    >
      <div
        className={cx(styles.connectorLine, {
          [styles.horizontalConnectorLine]: direction === "horizontal",
        })}
      />
      <div className={styles.connectorDot} />
    </div>
  );
};

type Props = {
  steps: Array<{ title: string; path: string }>;
  direction?: "vertical" | "horizontal";
};

const SecondStepper: FC<Props> = ({ steps, direction = "vertical" }) => {
  const { track } = useTrackers();

  const onStepClicked = (step: { title: string; path: string }) => {
    track("SuperLite Dashboard Stepper Clicked", {
      eventId: "superlite-dashboard-stepper-clicked",
      title: step.title,
    });
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.horizontalContainer]: direction === "horizontal",
      })}
    >
      {steps.map((each, idx) => (
        <Fragment key={idx}>
          <Link passHref href={each.path}>
            <Typography
              variant="body1"
              className={styles.stepText}
              onClick={() => onStepClicked(each)}
            >
              {each.title}
            </Typography>
          </Link>
          {idx !== steps.length - 1 && <Connector direction={direction} />}
        </Fragment>
      ))}
    </div>
  );
};

export default SecondStepper;
