import { FC, useState, useMemo, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import cx from "classnames";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import { useResumePdfQuery, PdfCodes } from "@/fetch/profiles/resume";
import { TickIcon, ChevronIcon } from "@/components/Icon";
import { Skeleton } from "@/components/Loader";
import Typography from "@/components/Typography";
import { useResponsive, useOpenExternalURL } from "@/hooks";
import Tabs, { Tab } from "@/components/Tabs";
import { CheckedIcon } from "@/components/Icon";
import styles from "./ResumePdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  pdf?: any;
  onClose: () => void;
};

const ResumePdfViewer: FC<Props> = ({ pdf, onClose }) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [resumeCode, setResumeCode] = useState<PdfCodes>(PdfCodes.Vancouver);
  const [resumeColor, setResumeColor] = useState<string>("");

  const { isMobile } = useResponsive();
  const openExternalUrl = useOpenExternalURL();

  const resumeTemplateTabs = [
    { title: "Vancouver", value: PdfCodes.Vancouver },
    { title: "New York", value: PdfCodes.NewYork },
    { title: "London", value: PdfCodes.London },
    { title: "Melbourne", value: PdfCodes.Melbourne },
  ];

  const resumeTemplateColors = useMemo(
    () => ({
      [PdfCodes.Vancouver]: ["#C6F1D1", "#EED8CC", "#FEE9FC", "#C9D4EE"],
      [PdfCodes.NewYork]: ["#FFAD72", "#A281D9", "#DF4BD9", "#37D2B6"],
      [PdfCodes.London]: ["#003749", "#887B05", "#025A35", "#5E0674"],
      [PdfCodes.Melbourne]: ["#AC8600", "#0095CD", "#EB46BD", "#DCC818"],
    }),
    []
  );

  const apolloActualColors = [
    { key: "#C6F1D1", value: "#FBFFFC" },
    { key: "#EED8CC", value: "#FFF9F5" },
    { key: "#FEE9FC", value: "#FDF4FC" },
    { key: "#C9D4EE", value: "#ECF0FA" },
  ];

  const currentTemplateColors = useMemo(
    () => resumeTemplateColors[resumeCode],
    [resumeCode, resumeTemplateColors]
  );

  useEffect(() => {
    setResumeColor(currentTemplateColors[0]);
  }, [currentTemplateColors]);

  const getResumeColor = () => {
    if (resumeCode === PdfCodes.Vancouver) {
      const actualColor = apolloActualColors.find(
        (each) => each.key === resumeColor
      );
      return actualColor?.value.slice(1) || "";
    }
    return resumeColor.slice(1);
  };

  const {
    data: resumePdfFileData,
    isLoading: isLoadingResumePdf,
    isFetched: isFetchedPdf,
    refetch,
  } = useResumePdfQuery(
    {
      code: resumeCode,
      color:
        resumeCode === PdfCodes.Vancouver
          ? apolloActualColors
              .find((each) => each.key === resumeColor)
              ?.value.slice(1) || ""
          : resumeColor.slice(1),
      showEmail: true,
      showPhone: true,
      showSkype: true,
    },
    { enabled: false, refetchOnWindowFocus: false, cacheTime: 0 }
  );

  useEffect(() => {
    refetch();
  }, [resumeColor]);

  const pdfUrl = resumePdfFileData?.url || "";

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const pagesArray = new Array(numPages).fill(1);

  const isLoading = isLoadingResumePdf;

  const handleTabChange = (event: React.SyntheticEvent, newValue: PdfCodes) => {
    setResumeCode(newValue);
  };

  const vancouverImage =
    "https://gwatco-res.cloudinary.com/image/upload/v1709192262/gwat.gworld/assets/Template/Apollo-p1.png";
  const newYorkImage =
    "https://gwatco-res.cloudinary.com/image/upload/v1709192261/gwat.gworld/assets/Template/Vega-p1.png";
  const londonImage =
    "https://gwatco-res.cloudinary.com/image/upload/v1709192261/gwat.gworld/assets/Template/Terra-p1.png";
  const melbourneImage =
    "https://gwatco-res.cloudinary.com/image/upload/v1709192261/gwat.gworld/assets/Template/Tempe-p1.png";

  const onDownload = () => {
    openExternalUrl(pdfUrl);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      <DialogTitle
        hasCloseButton
        onClose={onClose}
        className={styles.dialogTitle}
      >
        Preview & Download
        {!isMobile && (
          <div className={styles.colorContainer}>
            {currentTemplateColors.map((each, idx) => (
              <div
                key={idx}
                className={styles.color}
                onClick={() => setResumeColor(each)}
                style={{ background: each }}
              >
                {resumeColor === each && (
                  <TickIcon className={styles.checkIcon} />
                )}
              </div>
            ))}
          </div>
        )}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.leftContainer}>
          <div className={styles.templateItem}>
            <Typography variant="body2" color="text.contrast">
              Vancouver
            </Typography>
            <div className={styles.imageContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={vancouverImage}
                alt="apollo-template"
                className={styles.templateImage}
                onClick={() => setResumeCode(PdfCodes.Vancouver)}
              />
              {resumeCode === PdfCodes.Vancouver && (
                <div className={styles.selectedTemplate}>
                  <CheckedIcon className={styles.selectedIcon} />
                  <div className={styles.selectedBar} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.templateItem}>
            <Typography variant="body2" color="text.contrast">
              New York
            </Typography>
            <div className={styles.imageContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={newYorkImage}
                alt="terra-template"
                className={styles.templateImage}
                onClick={() => setResumeCode(PdfCodes.NewYork)}
              />
              {resumeCode === PdfCodes.NewYork && (
                <div className={styles.selectedTemplate}>
                  <CheckedIcon className={styles.selectedIcon} />
                  <div className={styles.selectedBar} />
                </div>
              )}
            </div>
          </div>

          <div className={styles.templateItem}>
            <Typography variant="body2" color="text.contrast">
              London
            </Typography>
            <div className={styles.imageContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={londonImage}
                alt="temp-template"
                className={styles.templateImage}
                onClick={() => setResumeCode(PdfCodes.London)}
              />
              {resumeCode === PdfCodes.London && (
                <div className={styles.selectedTemplate}>
                  <CheckedIcon className={styles.selectedIcon} />
                  <div className={styles.selectedBar} />
                </div>
              )}
            </div>
          </div>

          <div className={styles.templateItem}>
            <Typography variant="body2" color="text.contrast">
              Melbourne
            </Typography>
            <div className={styles.imageContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={melbourneImage}
                alt="vega-template"
                className={styles.templateImage}
                onClick={() => setResumeCode(PdfCodes.Melbourne)}
              />
              {resumeCode === PdfCodes.Melbourne && (
                <div className={styles.selectedTemplate}>
                  <CheckedIcon className={styles.selectedIcon} />
                  <div className={styles.selectedBar} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Typography variant="h6" className={styles.templateTitle}>
          Choose a template
        </Typography>

        <Tabs
          className={styles.tabs}
          value={resumeCode}
          onChange={handleTabChange}
        >
          {resumeTemplateTabs.map((item) => (
            <Tab
              aria-selected={item.value === resumeCode}
              label={item.title}
              key={item.value}
              tabIndex={0}
              value={item.value}
              className={cx({
                [styles.selectedTab]: resumeCode === item.value,
              })}
            />
          ))}
        </Tabs>
        <div className={styles.pdfContainer}>
          {isLoading && !pdfUrl ? (
            <>
              <Skeleton className={styles.loadingPdf} />
            </>
          ) : (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className={styles.documentContainer}
            >
              <div className={styles.mainPagesContainer}>
                <Page
                  pageNumber={pageNumber}
                  className={styles.mainPage}
                  width={isMobile ? 343 : 690}
                  height={isMobile ? 640 : 960}
                />
              </div>
            </Document>
          )}
          {!isLoading && pdfUrl && (
            <div className={styles.pageNumberContainer}>
              <ChevronIcon
                dir="left"
                onClick={() => {
                  if (pageNumber === 1) return;
                  setPageNumber(pageNumber - 1);
                }}
                className={cx(styles.chevronIcon, {
                  [styles.disabledChevronIcon]: pageNumber === 1,
                })}
              />
              <Typography variant="body1" color="text.contrast">
                {pageNumber}&nbsp;/&nbsp;{numPages}
              </Typography>
              <ChevronIcon
                dir="right"
                onClick={() => {
                  if (pageNumber >= numPages) return;
                  setPageNumber(pageNumber + 1);
                }}
                className={cx(styles.chevronIcon, {
                  [styles.disabledChevronIcon]: pageNumber >= numPages,
                })}
              />
            </div>
          )}
        </div>
        <div className={styles.mobileColorRoot}>
          <Typography variant="body1">Accent colors</Typography>
          <div className={styles.mobileColorContainer}>
            {currentTemplateColors.map((each, idx) => (
              <div
                key={idx}
                className={styles.color}
                onClick={() => setResumeColor(each)}
                style={{ background: each }}
              >
                {resumeColor === each && (
                  <TickIcon className={styles.checkIcon} />
                )}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.actionContainer}>
        <Button className={styles.actionButton} onClick={onDownload}>
          Download
        </Button>
        <Button
          variant="outlined"
          className={styles.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResumePdfViewer;
