import { FC, useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import { DownloadIcon, RedirectIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { Skeleton } from "@/components/Loader";
import { useOpenExternalURL, useResponsive, useTrackers } from "@/hooks";
import styles from "./GeneralPdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  pdfUrl: string;
  onClose: () => void;
  title: string;
};

const GeneralPdfViewer: FC<Props> = ({ pdfUrl, onClose, title }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { track } = useTrackers();

  const openExternalLink = useOpenExternalURL();
  const { isMobile } = useResponsive();
  const pagesContainerRef = useRef<HTMLDivElement | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const pagesArray = new Array(numPages).fill(1);

  const openInNewTab = () => {
    openExternalLink(pdfUrl || "");
  };

  const onDownload = () => {
    openExternalLink(pdfUrl || "");
    track("PDF Downloaded", {
      eventId: "pdf-downloaded",
      pdfTitle: title,
    });
  };

  const mobilePageWidth = pagesContainerRef.current?.offsetWidth || 300;
  const mobilePageHeight = mobilePageWidth * 1.41;

  useEffect(() => {
    track("PDF Viewer Opened", {
      eventId: "pdf-viewer-opened",
      pdfTitle: title,
    });
  }, []);

  const handleChangePage = (newPage: number) => {
    track("PDF Page Changed", {
      eventId: "pdf-page-changed",
      pdfTitle: title,
      page: newPage,
    });
    setPageNumber(newPage);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      <DialogTitle
        hasCloseButton
        onClose={onClose}
        className={styles.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className={styles.documentContainer}
        >
          <div className={styles.leftContainer} ref={pagesContainerRef}>
            {pagesArray.map((each, idx) => (
              <div key={idx} className={styles.sidePageContainer}>
                <Page
                  pageNumber={idx + 1}
                  className={styles.sidePage}
                  width={isMobile ? mobilePageWidth : 178}
                  height={isMobile ? mobilePageHeight : 251}
                  onClick={
                    isMobile ? undefined : () => handleChangePage(idx + 1)
                  }
                />
                <Typography variant="body1" color="text.contrast">
                  {idx + 1}
                </Typography>
              </div>
            ))}
          </div>
          <div className={styles.mainPageContainer}>
            {isMobile ? (
              pagesArray.map((each, idx) => (
                <Page
                  key={idx}
                  pageNumber={idx + 1}
                  className={styles.mainPage}
                />
              ))
            ) : (
              <Page
                pageNumber={pageNumber}
                className={styles.mainPage}
                width={727}
                height={1028}
              />
            )}
          </div>
        </Document>
      </DialogContent>
      <DialogActions className={styles.actionContainer}>
        <Button
          startIcon={<DownloadIcon />}
          onClick={onDownload}
          className={styles.downloadButton}
        >
          Download
        </Button>
        {/* <Button
          variant="outlined"
          endIcon={<RedirectIcon />}
          className={styles.secondaryButton}
          onClick={openInNewTab}
        >
          Open in new tab
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default GeneralPdfViewer;
